import React, { Component } from 'react';
import './TOS.scss';
export default function TOS() {
	return (
		<div className="tos-container">
			<div className="title"> Terms of Service</div>
			<div className="tos-text">
				<div className="tos-subheader">Payment</div>
				Lorem, ipsum dolor sit amet consectetur
				adipisicing elit. Vel necessitatibus minus
				incidunt consequatur quo maxime eius
				architecto. Laborum labore, possimus porro
				optio, molestias eligendi a eaque, inventore
				assumenda natus perferendis. Incidunt
				sapiente quam ad ipsa. Repellendus
				laudantium magnam quas quo nulla eius,
				sapiente aliquam eos iste beatae tempora!
				Eligendi quos suscipit magnam soluta animi
				laborum eveniet nemo at ipsam corporis.
				Voluptates totam deserunt aspernatur facere
				quas autem vitae, facilis quaerat illum sint
				aut, quisquam tempora voluptatum ducimus
				assumenda excepturi placeat qui velit at
				veritatis saepe sed. Facere accusantium
				veniam aliquam. Fuga recusandae assumenda
				fugiat, veniam veritatis itaque. Sequi ipsa
				at voluptatibus optio. Fugit earum sequi
				animi expedita tempora sunt at quidem
				possimus nisi alias, nemo aut, architecto ab
				error quaerat. Laborum est reiciendis,
				quibusdam alias asperiores dignissimos ullam
				tempora, adipisci nam quasi neque saepe
				mollitia! Ratione sequi nemo fugit, aperiam
				alias maxime eligendi hic et neque molestias
				velit cumque provident. Maxime, ducimus.
				Eius ipsam id libero suscipit consequatur
				ducimus ut illo corrupti excepturi officia
				ad officiis alias ullam dolores numquam
				dicta, praesentium dolore cumque
				accusantium, laboriosam magni! Quasi,
				possimus doloribus! Maiores corporis
				voluptates consequatur aut? Esse corporis,
				natus impedit possimus vitae debitis harum
				corrupti qui magni beatae minima quibusdam,
				nam sapiente mollitia tempore eum dolorem
				tenetur quos unde id? Cupiditate! Nesciunt,
				harum nisi laboriosam facilis earum unde
				ipsam iusto nobis vel asperiores, veniam
				voluptate, repellendus aut sunt molestiae
				blanditiis quasi ab numquam tenetur dolore
				ipsa inventore eum placeat. Ducimus, magnam.
				Quae, molestias aut fugit praesentium
				nostrum libero vel, maxime odio odit
				asperiores enim similique fuga. Ullam odio,
				optio sit accusantium, maxime officia quos
				assumenda est quod ipsa atque aut. Vero!
				Quod illum, omnis similique rerum excepturi
				laudantium, non explicabo, eius neque alias
				facere dolores repellat quasi possimus
				provident maxime ad voluptas? Sequi, nemo
				eveniet? Dignissimos eveniet eius magnam
				dolor provident! Ipsum tempora modi eligendi
				cupiditate veritatis dolor, vitae quod
				provident perspiciatis deserunt maxime?
				Eveniet debitis vero consectetur adipisci
				culpa voluptatum laudantium atque deleniti
				corporis delectus, maiores porro incidunt
				similique animi? Pariatur corporis odit
				exercitationem molestiae enim dicta sunt
				aperiam similique. Iure magnam doloremque
				velit, quo autem reprehenderit minus optio
				temporibus dolore iusto ab vitae, dicta
				exercitationem nesciunt laboriosam ex
				debitis! Voluptate voluptatem nostrum vel
				atque nam minima saepe, harum odio, incidunt
				quibusdam repellendus tenetur illum et
				mollitia, numquam excepturi. Repellat
				doloribus alias eius facilis totam nesciunt
				recusandae, necessitatibus ex harum. Quod
				possimus itaque ipsa pariatur, saepe culpa
				obcaecati dicta. Eius aliquam iure incidunt
				cum architecto, nam nihil quam? Ipsam
				recusandae ducimus eaque omnis aspernatur.
				Quos consequuntur laborum laudantium
				assumenda doloremque? Illo exercitationem
				nostrum vero, incidunt iste architecto
				officiis placeat nulla officia quos? Omnis
				asperiores cum totam unde dolore
				necessitatibus sunt. Beatae optio minus
				eligendi dolores delectus odit quas
				laboriosam quibusdam! At amet, enim
				blanditiis aperiam animi fugiat quibusdam
				soluta ab dolores, tempora consequatur magni
				alias autem neque quidem. Eos quod minus
				veniam quasi rem porro autem ut laborum
				obcaecati laudantium? Aspernatur odio eum
				doloremque asperiores! Soluta dignissimos
				inventore doloremque cum quaerat consectetur
				possimus dicta illo voluptas, vel placeat
				reprehenderit, provident ducimus quo minus
				modi, iusto consequuntur porro perspiciatis
				natus distinctio. Perferendis aliquam
				voluptate numquam facere nulla modi deserunt
				laudantium fuga natus cupiditate assumenda
				nesciunt porro, deleniti autem voluptatibus
				est magnam fugit esse blanditiis dolore
				minima amet illum ducimus. Dolorum, ducimus?
				Quis doloribus corporis aperiam sit quia, a
				voluptatum sapiente, ab beatae magnam
				impedit ducimus. Placeat debitis voluptatem
				distinctio modi officiis ratione quos!
				Eligendi animi, laborum voluptate similique
				sequi voluptatum magni! In temporibus id
				nobis, itaque officia necessitatibus modi
				eveniet non et optio sequi minima soluta
				sunt enim fugiat consequatur ut distinctio
				molestias autem minus, rem, ullam laboriosam
				ad quia. Omnis! Cum consequuntur molestias
				minus odio voluptate similique qui
				repudiandae excepturi adipisci perspiciatis
				culpa blanditiis nihil exercitationem
				voluptatem, harum dignissimos! Sapiente
				ipsum suscipit, repudiandae exercitationem
				repellat ratione porro similique culpa fuga!
				Iusto neque et quasi, facere quidem cumque
				aspernatur, fuga architecto excepturi
				deleniti laboriosam mollitia nulla nisi
				asperiores eius quo nostrum, incidunt aut
				aperiam explicabo voluptate rem. Nobis
				nostrum unde fugit? Facilis velit repellat
				ratione quisquam, perspiciatis eos suscipit
				ad nam impedit soluta saepe, cupiditate
				aperiam voluptatem numquam laudantium
				tempora officia fugit aliquid fuga eius
				perferendis ea aliquam sequi nobis. Quos?
				Magnam eveniet ad quisquam est earum
				repudiandae sint, quis quod veritatis amet
				illo dolor cum placeat aperiam a sit
				cupiditate temporibus aspernatur sequi
				explicabo velit nulla error aliquid dolores?
				Distinctio. Incidunt veniam beatae neque
				asperiores aut non numquam vero, error
				recusandae cupiditate architecto quasi dicta
				saepe, eaque dignissimos? Distinctio beatae
				veniam itaque assumenda? Iste, laborum
				aspernatur modi maxime beatae quod. Adipisci
				totam commodi neque. Laudantium corrupti
				harum eligendi accusamus velit? Itaque
				possimus blanditiis dolor maiores minima
				amet provident quo facere iste qui odio ipsa
				ut ratione unde soluta, temporibus
				voluptatibus! Autem similique velit placeat
				nulla. Reprehenderit et placeat repellat
				saepe quia sunt ea beatae ducimus delectus
				eveniet similique vitae eos, dignissimos,
				reiciendis odit tempora ab aliquam deserunt
				praesentium. Laborum, veniam. Eos
				consequatur placeat aliquam eaque quasi hic
				recusandae? Soluta mollitia, blanditiis,
				tempore rem, accusantium vel nisi sapiente
				voluptatem natus suscipit voluptas maiores
				veniam ex explicabo similique aut
				cupiditate. Neque, at. Nesciunt ad similique
				ab pariatur quaerat neque deserunt minima,
				consequuntur facere vero unde veritatis
				doloribus perspiciatis laboriosam numquam
				voluptatem necessitatibus, natus sequi
				quibusdam ipsam aut quam tenetur saepe
				impedit! Placeat! Laborum blanditiis, quasi
				veniam sunt minus ullam eum perspiciatis
				dignissimos pariatur, consequuntur delectus
				id reprehenderit eos hic! Aut ex aperiam
				quis molestiae soluta, eligendi quos
				architecto magnam unde vel esse! Voluptate,
				ratione quasi pariatur corporis ab
				reprehenderit distinctio aperiam debitis
				quibusdam molestias sit qui repellat nihil
				sapiente quis, natus fugiat sint fuga!
				Laudantium eveniet libero fuga quam,
				quisquam quod debitis. Error voluptate
				animi, alias provident accusamus nulla autem
				voluptatem obcaecati. Accusantium suscipit
				iste minima labore! Eius excepturi rem,
				neque nobis quaerat provident quis.
				Explicabo reprehenderit ullam atque id sit
				aut. Earum sed, enim rerum nisi dolore
				commodi? Ad fugit esse animi quis a qui odit
				beatae iusto earum? Ducimus dolore similique
				commodi eveniet quas! Earum rem hic numquam
				aperiam fugit! Nulla quos commodi ullam
				similique sit expedita. Vitae nisi quo
				delectus? Nam, nulla optio. Doloremque vel
				omnis, nemo voluptate delectus deserunt est
				fugiat mollitia molestiae hic ut
				repellendus, tenetur nam? Cum laudantium
				totam quas, quidem alias accusantium qui hic
				rem nostrum tempora itaque nesciunt
				cupiditate ratione unde veniam corrupti
				consequuntur dolorum facilis. Saepe aliquam,
				dolor ipsa exercitationem tempora esse
				earum? Quos non veritatis a saepe illum esse
				nihil quis aut aliquam ad officiis officia,
				dolorem obcaecati fuga eos sunt provident
				explicabo nam nisi. Harum maxime, quisquam
				dolores sed vel cupiditate? Quia quis sit
				minus facilis, facere exercitationem beatae
				cum labore, asperiores blanditiis at?
				Tempore, nostrum ex inventore enim magnam
				nobis nesciunt sit cupiditate ipsum
				consequatur hic aperiam laboriosam, saepe
				totam. Omnis deserunt consequatur, aliquid
				aut distinctio ut vel commodi! In amet
				dolorem cupiditate atque dolorum deserunt
				laudantium esse consectetur, aspernatur
				blanditiis vero alias tenetur ex dignissimos
				odit similique qui necessitatibus! Laborum
				dolor enim mollitia corrupti quidem
				exercitationem sed eaque suscipit doloribus
				accusamus. Harum mollitia provident est quae
				expedita totam optio voluptate amet, tenetur
				laborum voluptates quis hic, voluptas
				quisquam ipsum. Fuga repellat officiis
				facilis beatae ut iste obcaecati, adipisci
				maxime at magnam odio deleniti eius sequi
				ipsum quo sed dignissimos ratione ullam
				consequatur. Similique adipisci sequi ad
				autem unde veniam. Unde maiores fugiat
				aliquam dignissimos animi dolor modi, ipsa
				quia! Veniam non nulla quam illo deserunt
				incidunt omnis officia id obcaecati. Magni
				recusandae temporibus molestias, odio
				maiores error eligendi consequuntur? Saepe
				ipsam molestiae asperiores nobis iure quis
				blanditiis qui incidunt error omnis,
				eligendi quia quam delectus eum laboriosam
				esse sapiente nisi ad iusto veniam aliquid,
				tenetur voluptatibus. Deserunt, tempora
				provident. Necessitatibus facere numquam
				expedita molestiae vero praesentium, harum
				maxime ut, ipsa iure sit. Quo itaque
				pariatur possimus optio molestiae deleniti
				quibusdam repudiandae laudantium saepe
				placeat, architecto et tempore excepturi.
				Pariatur. Animi rerum sunt, veritatis
				deserunt est odio ea fuga aperiam iste,
				molestias placeat reprehenderit perspiciatis
				eligendi facere maiores maxime ratione ipsum
				a asperiores voluptas vitae. Exercitationem
				quasi eligendi minima incidunt? Similique
				vel aliquid quo velit cum nesciunt, eaque
				maiores laboriosam expedita laudantium quasi
				obcaecati rem dolorem magni officiis
				voluptates sit quos magnam labore laborum
				eius mollitia odio at? Eos, vero. Pariatur
				laboriosam rem reprehenderit quo doloribus
				esse tenetur maxime natus saepe sunt
				molestiae laudantium excepturi molestias,
				porro iure deleniti consectetur at mollitia
				voluptates ipsam? Quo quae vel adipisci
				ducimus ullam? Maiores, alias. Voluptatum
				alias eveniet quo et. Nisi, cumque nam
				itaque reiciendis, quis repellat quia,
				asperiores libero ipsum excepturi dolorum ea
				mollitia beatae facere tenetur eum expedita.
				Maiores, ex unde? Voluptatibus nesciunt
				veniam tenetur quasi! Tempora autem
				perspiciatis excepturi odio deleniti quam
				accusantium voluptatem sed obcaecati iure,
				consequuntur dolorum, quaerat molestias a
				laboriosam sint ipsum ipsam non id iste?
				Adipisci? Pariatur doloremque rem veniam
				iure temporibus accusamus placeat amet
				exercitationem voluptas ex? Quod sunt optio
				aperiam aut, sed accusantium enim minus
				asperiores numquam cumque eos rem! Possimus
				fugit quo excepturi. Corporis provident
				nulla iusto atque voluptas, ut, cupiditate
				non repellendus possimus voluptate officia
				quod aut vel enim quas deserunt. Rerum,
				consectetur accusamus. Consectetur
				repudiandae est tenetur sed perferendis in
				non. Saepe quasi sit autem illum tempore,
				facere deserunt aspernatur quae, earum
				debitis repudiandae praesentium atque, quia
				dignissimos eum amet nam! Aliquam unde
				voluptatibus vitae eum. Iusto esse
				blanditiis in sint. Amet nostrum quas
				eveniet repellat, laudantium nobis iste
				temporibus esse! Deserunt nulla obcaecati
				culpa excepturi esse, fugit fugiat maxime
				iure cupiditate beatae quas nihil quidem
				totam ducimus sequi ipsum aliquam. Nisi illo
				accusamus repellat ea officiis tenetur optio
				soluta consectetur ad obcaecati. Repellat
				magni nam ipsa quisquam animi impedit dicta?
				Et consequuntur reiciendis minima possimus
				perspiciatis maiores recusandae labore eos?
				Voluptates possimus voluptatibus cumque enim
				laborum, hic placeat nobis nulla expedita
				quod iusto minus minima dolorum labore
				aspernatur odit inventore dolor omnis veniam
				praesentium ratione blanditiis! Nobis eos
				fugit quisquam. Quam tempora voluptates
				laudantium, nam minima voluptatum
				reprehenderit consequuntur. Quia id fugiat,
				deserunt possimus obcaecati cum porro
				magnam. Repellendus dicta autem repellat
				inventore at voluptas obcaecati earum cum
				itaque similique? Eius dignissimos itaque
				repellendus aliquid doloremque nemo
				perferendis eaque aperiam commodi autem quis
				aliquam nesciunt ad, exercitationem modi
				mollitia harum explicabo et laboriosam nulla
				ea. Magnam est obcaecati distinctio numquam.
				Omnis, vel quibusdam, exercitationem
				cupiditate officiis architecto reiciendis
				illo illum voluptatem repellat, ex
				consequuntur soluta enim. Vitae qui
				sapiente, maiores magnam sit cupiditate
				mollitia iusto quis, quasi quos blanditiis
				accusantium! Quaerat consectetur
				voluptatibus doloribus. Magni tenetur
				recusandae nulla accusantium ducimus
				voluptatibus earum sed et itaque rerum modi
				odit iste commodi aperiam dignissimos sit at
				iusto natus, voluptates maxime explicabo
				deserunt. Officiis nesciunt illo ad quaerat
				quas eos voluptatem, odit id quis ipsum
				earum fugiat saepe quos deleniti possimus
				ducimus cum fugit laboriosam tempore dolor
				accusamus fuga eveniet quia. Quasi,
				dignissimos? Facere cupiditate excepturi
				tenetur tempore? Odio quam, amet, sapiente
				quia molestias ullam temporibus fuga ducimus
				ipsum, magni modi. Culpa impedit, voluptatum
				quasi eum quod voluptatem maiores deleniti
				doloribus ut illo. Totam culpa nobis
				praesentium explicabo accusantium maxime
				placeat a, maiores excepturi laudantium
				dolore nesciunt corrupti dolorem provident
				iste voluptates? Temporibus deserunt
				perspiciatis, voluptas voluptates magni
				ipsam reprehenderit qui odit sint. Maiores
				cupiditate animi corrupti perferendis, enim
				ea optio aliquid, nostrum earum rem nam
				laboriosam sit molestiae a quos. Fuga eos
				nam, molestias tempore quaerat accusamus ea
				doloribus quis suscipit nesciunt. Architecto
				nam inventore explicabo, magni fugiat,
				maxime modi rerum odit ad vero nulla nihil
				animi, in laudantium delectus error adipisci
				quis. Repellendus impedit libero quos magni
				doloremque sit inventore aliquam. Saepe,
				dolorum consequatur officiis sint obcaecati
				omnis fuga provident iusto porro itaque
				pariatur sed eligendi nostrum rem odio
				debitis voluptates repellendus fugit. Ad,
				saepe quo! Sapiente qui nam rem assumenda.
				Totam accusantium optio quaerat. Libero,
				inventore, earum nulla deleniti harum itaque
				minima fugiat odio accusantium deserunt
				quasi hic ratione aliquid, obcaecati sequi
				porro enim. Perferendis debitis laudantium
				id iste veritatis. Animi deserunt maiores et
				vero, tempore quidem tenetur rerum optio
				nesciunt corrupti cupiditate minima,
				voluptatum eius voluptates esse harum eaque
				recusandae quaerat maxime facilis nemo,
				expedita dolore repellendus? Illum, iusto?
				Exercitationem enim molestiae dolore fuga
				natus asperiores voluptatum facere maiores,
				illo, quae est. Corrupti, atque! Amet,
				doloremque soluta? Itaque culpa maxime
				cumque velit veritatis odit quis ex at eius
				hic. Ut libero, exercitationem autem
				temporibus corrupti eum iure numquam impedit
				molestias voluptatibus. Expedita, fuga.
				Cupiditate facilis maxime qui deleniti
				impedit nam quasi similique sunt, delectus,
				rem in animi possimus repudiandae. Voluptas
				ipsam, quisquam eos deserunt debitis earum
				asperiores est repellat, iure omnis
				architecto nihil quos illum culpa
				voluptatibus animi suscipit perferendis
				ducimus reiciendis eligendi sequi molestias
				nobis. Esse, laboriosam aperiam? Enim
				similique nulla tenetur ipsa molestias totam
				aliquid dolores explicabo. Possimus libero
				repudiandae labore aperiam molestiae, minima
				voluptate corrupti unde, nostrum optio
				neque! Quas possimus expedita vero
				praesentium unde culpa.
			</div>
		</div>
	);
}
